import React, { useEffect, useRef, useState } from "react"
import Flickity from "react-flickity-component"
import "flickity/dist/flickity.min.css"
import { Link, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import MarkDown from "react-markdown"

//component
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import LiveChat from "../../components/Home/livechat";
import TestimonialsText from "../../components/Home/testimonials-text"

// img
import bundleBannerimgLight from "../../../images/uploads/bundle/bundle-banner-light.png"
import bundleBannerimgdark from "../../../images/uploads/bundle/bundle-banner-dark.png"
import bundleDemolight from "../../../images/uploads/bundle/bundle-demo-light.png"
import bundleDemodark from "../../../images/uploads/bundle/bundle-demo-dark.png"
import bundleChallangeImg from "../../../images/uploads/bundle/bundle-challange-img.png"
import bundleChallengLight from "../../../images/uploads/bundle/bundle-challange-banner-light.png"
import bundleChallengDark from "../../../images/uploads/bundle/bundle-challange-banner-dark.png"
import bundleSolDark1 from "../../../images/uploads/bundle/bundle-sol-dark-1.png"
import bundleSolLight1 from "../../../images/uploads/bundle/bundle-sol-light-1.png"
import bundleSolDark2 from "../../../images/uploads/bundle/bundle-sol-dark-2.png"
import bundleSolLight2 from "../../../images/uploads/bundle/bundle-sol-light-2.png"
import bundleSolDark3 from "../../../images/uploads/bundle/bundle-sol-dark-3.png"
import bundleSolLight3 from "../../../images/uploads/bundle/bundle-sol-light-3.png"
import bundles1 from "../../../images/uploads/bundle/bundles1.png"
import bundles2 from "../../../images/uploads/bundle/bundles2.png"
import bundles3 from "../../../images/uploads/bundle/bundles3.png"
import bundles4 from "../../../images/uploads/bundle/bundles4.png"
import bundles5 from "../../../images/uploads/bundle/bundles5.png"
import bundles6 from "../../../images/uploads/bundle/bundles6.png"
import bundles7 from "../../../images/uploads/bundle/bundles7.png"
import bundles8 from "../../../images/uploads/bundle/bundles8.png"
import bundles9 from "../../../images/uploads/bundle/bundles9.png"
import bundles10 from "../../../images/uploads/bundle/bundles10.png"
import bundles11 from "../../../images/uploads/bundle/bundles11.png"
import bundles12 from "../../../images/uploads/bundle/bundles12.png"
import bundles13 from "../../../images/uploads/bundle/bundles13.png"
import bundles14 from "../../../images/uploads/bundle/bundles14.png"
import bundles15 from "../../../images/uploads/bundle/bundles15.png"
import bundles16 from "../../../images/uploads/bundle/bundles16.png"


import addcs1 from "../../../images/uploads/decodeup-mahoni-additional-case-study.png"
import addcs2 from "../../../images/uploads/decodeup-greenwood-additional-case-study.png"

const Bundle = props => {

  const SEOdata = props.data.pageData.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.pageData.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.pageData.edges[0].node.frontmatter?.Hreflang


  const [margin, setMargin] = useState()
  const flRef = useRef()

  useEffect(() => {
    let box = document.querySelector(".container")
    let width = box.offsetWidth
    let innerw = window.innerWidth
    let paddingApplied = innerw - width

    setMargin(paddingApplied)
  })

  const options = {
    // contain: true,
    // wrapAround: true,
    // initialIndex: 2,
    // pageDots: false,
    // autoPlay: false,
    // prevNextButtons: false,
    // adaptiveHeight: true,
    lazyLoad: true,
    prevNextButtons: true,
    cellAlign: "left",
    contain: true,
    freeScroll: true,
    pageDots: false,
    percentPosition: true,
    adaptiveHeight: true,
  }

  const onMouseEnter = () => {
    flRef.current.flkty.player.play()
  }
  const onMouseLeave = () => {
    flRef.current.flkty.player.stop()
  }
  return (
    <>
      <Layout>
        <Seo
          title={SEOdata ? SEOdata.seo_title : "bundle"}
          description={SEOdata ? SEOdata.seo_description : ""}
          keywords={SEOdata ? SEOdata.seo_keyword : ""}
          image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
          single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
          currentPageUrlPath={props.location.pathname}
          image_format={SEOdata ? SEOdata.image_format : ""}
          image_height={SEOdata ? SEOdata.feature_image_height : ""}
          img_width={SEOdata ? SEOdata.img_width : ""}
          facebook_url={SEOdata ? SEOdata.facebook_url : ""}
          twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
          twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
          twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
          twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
          page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
          page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
          sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
          breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
          logo_schema={Schemadata ? Schemadata.logo_schema : ""}
          organization_schema={Schemadata ? Schemadata.organization_schema : ""}
          aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
          local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
          site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
          speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
          hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
          hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
          hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
          hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
        />

        {/* banner  section */}
        <section className="h-full  xl:h-screen case-draft-section">
          <div className="h-full container mx-auto  ">
            <div className="pb-20 pt-1-63 md:py-2-00 lg:pt-64 h-full flex flex-col justify-center">
              <div className="mb-6-6 lg:mb-50 w-full  case-study-project-heading ">
                <h4 className="pr-5 lg:pl-0 mb-1-2 lg:mb-2-2 font-poppings-normal text-p3 lg:text-p2 text-term-primary">
                  Responsive website, Businesses Collaborate platform
                </h4>
                <h1 className="mb-3 lg:mb-5 font-poppings-bold text-h2 lg:text-h0 xl:text-h1 text-term-primary">
                  Travel & Hospitality Deals Platform
                </h1>
                <p className="w-full lg:w-11/20  font-worksans-normal text-p4  md:text-h5 lg:text-p2 text-term-primary">
                  In 2020, the COVID-19 pandemic impacted every single business
                  in the world in some way, and most of that impact was
                  negative. BUNDL was established to address the challenges of
                  the pandemic, which hurt retail, hospitality and tourism the
                  most. This platform helps companies to collaborate and offer
                  exclusive deals.
                </p>
              </div>

              <div className="project-details-draft">
                <div className="flex flex-wrap lg:flex-nowrap">
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      CLIENT
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Graham
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      COUNTRY
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      New Zealand
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      SERVICES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Development, SEO, Maintenance and Support, UI/UX Design,
                      Product management, Hosting and Maintenance
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      TECHNOLOGIES USED
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      React JS, Node JS, Flutter, Tailwind, Gatsby JS,  Wordpress
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      INDUSTRIES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Business to Business
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Banner img section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <img
              className="mx-auto bundleBannerimglight"
              src={bundleBannerimgLight}
              alt="Bundle Decodeup Technologies"
            />
            <img
              className="mx-auto bundleBannerimgdark"
              src={bundleBannerimgdark}
              alt="Bundle Decodeup Technologies"
            />
          </div>
        </section>

        {/* Idea behind Business */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="flex flex-wrap sm:flex-nowrap gap-4 md:gap-8 lg:gap-16">
              <div className="w-full sm:w-1/3 lg:w-2/5 max-w-3-84">
                <h3 className="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Idea behind Business
                </h3>
              </div>
              <div className="w-full sm:w-2/3 lg:w-3/5">
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal ">
                  The client's idea was straightforward: register businesses
                  online and advertise their products and services. He needed a
                  platform to do this that was easy for two very distinct users
                  to navigate.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal ">
                  It also needed to be secure. We knew that our client wanted to
                  make a difference so we set out to help him do just that.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Bundle demo section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="flex flex-wrap sm:flex-nowrap gap-8 md:gap-8 lg:gap-16">
              <div className="w-full lg:w-1/2">
                <img
                  className="mx-auto bundleBannerimglight"
                  src={bundleDemolight}
                  alt="Bundle Decodeup Technologies"
                />
                <img
                  className="mx-auto bundleBannerimgdark"
                  src={bundleDemodark}
                  alt="Bundle Decodeup Technologies"
                />
              </div>
              <div className="w-full lg:w-1/2">
                <h3 className="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  Find great local places to eat, play and stay
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  The overall goal of the website is to advertise local
                  businesses and provide key contact information and details,
                  along with exclusive sales offers and bank accounts info.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  The site also serves as an online booking system for
                  businesses in the tourism industry. You can add and delete
                  services from your BUNDL and customize your packages.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  This New Zealand-based company collaborates with other
                  businesses in the country to bring lower prices to consumers.
                  This means consumers save with no unexpected fees or inflated
                  prices.
                </p>
                <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  The site provides additional tourism revenue for Wellington
                  and operates within three main categories: Eat, Rest, and
                  Play.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Bundle Intro blue section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="px-5 py-6 lg:py-72 lg:px-54 bundle-blue">
              <h3 className="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-white">
                This was a very fun project to work on because of the different
                components and the goal of helping businesses survive amidst the
                pandemic. This isn't to say it was an easy task. As you will
                see, there were quite a few obstacles to overcome but our team
                handled it with ease.
              </h3>
            </div>
          </div>
        </section>

        {/* Challanges section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="flex flex-wrap sm:flex-nowrap gap-8 md:gap-8 lg:gap-16">
              <div className="w-full sm:w-2/29 lg:max-w-3-68">
                <h2 className="mb-4 lg:mb-16 pr-10 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                  The Challenges
                </h2>
                <img
                  src={bundleChallangeImg}
                  alt="Bundle Decodeup Technologies"
                />
              </div>
              <div className="w-full sm:w-7/10 flex flex-wrap">
                <div className="md:px-4 py-3 lg:py-5 w-full md:w-1/2 border-b md:border-b-0 bundle-challenge-content">
                  <span className="mb-2 block text-term-primary font-poppings-bold bundle-span text-p3 md:text-sh3">
                    01
                  </span>
                  <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    After reviewing project requirements with the client, the
                    DecodeUp team began to analyze the needs and challenges of
                    the website to create the best possible space for BUNDL to
                    function.
                  </p>
                </div>
                <div className="md:px-4 py-3 lg:py-5 w-full md:w-1/2 md:border-l bundle-challenge-content">
                  <span className="mb-2 block text-term-primary font-poppings-bold bundle-span text-p3 md:text-sh3">
                    02
                  </span>
                  <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    Per usual, the website needed to be attractive and easy for
                    users to navigate on any device.
                  </p>
                </div>
                <div className="md:px-4 py-3 lg:py-5 w-full border-t border-b bundle-challenge-content">
                  <span className="mb-2 block text-term-primary font-poppings-bold bundle-span text-p3 md:text-sh3">
                    03
                  </span>
                  <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    There are two main audiences for the website. Due to this,
                    we needed to create a fully integrated backend management
                    system as well as a secure and intuitive frontend shopping
                    experience for consumers.
                  </p>
                </div>
                <div className="md:px-4 py-3 lg:py-5 w-full md:w-1/2 border-b md:border-b-0 bundle-challenge-content">
                  <span className="mb-2 block text-term-primary font-poppings-bold bundle-span text-p3 md:text-sh3">
                    04
                  </span>
                  <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    A signup form was also required for those interested in more
                    information. We also needed secure form details for banking
                    information, shipping, etc.
                  </p>
                </div>
                <div className="md:px-4 py-3 lg:py-5 w-full md:w-1/2 md:border-l  bundle-challenge-content">
                  <span className="mb-2 block text-term-primary font-poppings-bold bundle-span text-p3 md:text-sh3">
                    05
                  </span>
                  <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    This website leans on the Stripe payment integration which
                    isn't the easiest to use. The main admin account has to
                    connect with different user's accounts. The site also needs
                    to distribute commission payments to distributors.
                  </p>
                </div>
                <div className="md:px-4 py-3 lg:py-5 w-full border-t bundle-challenge-content">
                  <span className="mb-2 block text-term-primary font-poppings-bold bundle-span text-p3 md:text-sh3">
                    06
                  </span>
                  <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    Finally, on the front end customers have to search the BUNDL
                    inventory by categories and area to find packages they are
                    looking for. The Bundle listings have to be fully responsive
                    with quick clicks to add to user accounts. Customers also
                    need to modify and delete BUNDLs as needed..
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Banner img section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <img
              className="mx-auto bundleBannerimglight"
              src={bundleChallengLight}
              alt="Bundle Decodeup Technologies"
            />
            <img
              className="mx-auto bundleBannerimgdark"
              src={bundleChallengDark}
              alt="Bundle Decodeup Technologies"
            />
          </div>
        </section>

        {/* solutions */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="w-full mb-10 lg:mb-16">
              <h2 className="font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary text-center">
                Solutions
              </h2>
            </div>
            {/* 1 */}
            <div className="flex flex-col gap-10 md:gap-8 lg:gap-16">
              <div className="flex flex-wrap sm:flex-nowrap gap-4 md:gap-8 lg:gap-16 items-center">
                <div className="w-full sm:w-1/2">
                  <h3 className="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                    Revolutionizing Tourism
                  </h3>
                  <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    Our first priority was to establish businesses on the site
                    that were most impacted by the pandemic.
                  </p>
                  <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    We then set out to create the perfect management system that
                    admins could use to create categories and customize bundle
                    offerings.
                  </p>
                  <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    On the front end, we established signup for businesses and
                    consumers so they each had a separate entry point to the
                    site.
                  </p>
                  <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    The search function was our next step, making it possible
                    for users to easily search for bundles and make purchases.
                  </p>
                </div>
                <div className="w-full sm:w-1/2">
                  <img
                    className="mx-auto bundleBannerimglight"
                    src={bundleSolLight1}
                    alt="Bundle Decodeup Technologies"
                  />
                  <img
                    className="mx-auto bundleBannerimgdark"
                    src={bundleSolDark1}
                    alt="Bundle Decodeup Technologies"
                  />
                </div>
              </div>
              {/* 2 */}
              <div className="flex flex-wrap sm:flex-nowrap gap-4 md:gap-8 lg:gap-16 items-center">
                <div className="sm:order-2 w-full sm:w-1/2">
                  <h3 className="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                    Publish Custom Bundles
                  </h3>
                  <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    We created a platform that each business can use by creating
                    an account and setting up bundles. They can enter their own
                    details like address, photos, services, contact info and
                    more. They can also add their hours of operations and
                    pricing.
                  </p>
                  <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    Consumers can enjoy a feature that lets them calculate
                    prices for select bundles to update or modify and remove
                    services they don't need or want.
                  </p>
                </div>
                <div className="sm:order-1 w-full sm:w-1/2">
                  <img
                    className="mx-auto bundleBannerimglight"
                    src={bundleSolLight2}
                    alt="Bundle Decodeup Technologies"
                  />
                  <img
                    className="mx-auto bundleBannerimgdark"
                    src={bundleSolDark2}
                    alt="Bundle Decodeup Technologies"
                  />
                </div>
              </div>
              {/* 3 */}
              <div className="flex flex-wrap sm:flex-nowrap gap-4 md:gap-8 lg:gap-16 items-center">
                <div className="w-full sm:w-1/2">
                  <h3 className="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                    Custom payment portals for vendors
                  </h3>
                  <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    With the demands of this unique eCommerce site, we created
                    custom payment coding for this client so consumers can buy
                    any bundle and the specified commission amount will go
                    straight to the distributor.
                  </p>
                  <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    After 7 days, distributors receive their commission directly
                    to their bank account.
                  </p>
                </div>
                <div className="w-full sm:w-1/2">
                  <img
                    className="mx-auto bundleBannerimglight"
                    src={bundleSolLight3}
                    alt="Bundle Decodeup Technologies"
                  />
                  <img
                    className="mx-auto bundleBannerimgdark"
                    src={bundleSolDark3}
                    alt="Bundle Decodeup Technologies"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="mb-8 lg:mb-12 flex flex-wrap sm:flex-nowrap gap-4 md:gap-8 lg:gap-16">
              <div className="w-full sm:w-2/5 max-w-480">
                <h2 className="font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                  Typeface & <br className="hidden sm:block" />
                  Colors
                </h2>
              </div>
              <div className="w-full sm:w-3/5 bundle-typo text-term-primary">
                <h4 className="mb-2 lg:mb-8 text-term-primary">Jost</h4>
                <h5 className="mb-2 lg:mb-6 text-term-primary opacity-70">
                  ABCDEFGHIJKLMNOPQRSTUVWXYZ
                </h5>
                <h6 className="text-term-primary opacity-70">
                  abcdefghijklmnopqrstuvwxyz
                </h6>
              </div>
            </div>
            <div className="mb-12 flex flex-wrap sm:flex-nowrap bundle-color">
              <div className="p-4 lg:p-8 w-full sm:w-3/5 bundle-co-1">
                <p className="text-rp1 lg:text-h3 font-poppings-bold text-white text-center lg:text-left">
                  #6650A3
                </p>
              </div>
              <div className="p-4 lg:p-8 w-1/2 sm:w-1/5 lg:max-w-240 bundle-co-2">
                <p className="text-rp1 lg:text-h3 font-poppings-bold text-white text-center">
                  #FC1055
                </p>
              </div>
              <div className="p-4 lg:p-8 w-1/2 sm:w-1/5 lg:max-w-240 bundle-co-3">
                <p className="text-rp1 lg:text-h3 font-poppings-bold text-white text-center">
                  #222222
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* The Result */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="flex flex-wrap sm:flex-nowrap gap-4 md:gap-8 lg:gap-16">
              <div className="w-full sm:w-2/29 lg:max-w-3-68">
                <h2 className="lg:w-1/2 pr-10 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                  The Result
                </h2>
              </div>
              <div className="w-full sm:w-7/10 flex flex-wrap">
                <div className="md:px-4 py-3 lg:py-5 w-full md:w-1/2 border-b bundle-challenge-content">
                  <span className="mb-2 block text-term-primary font-poppings-bold bundle-span text-p3 md:text-sh3">
                    01
                  </span>
                  <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    Despite every challenge, we found a way to bring this client
                    the exact tool they needed and to help so many local
                    businesses in the process.
                  </p>
                </div>
                <div className="md:px-4 py-3 lg:py-5 w-full md:w-1/2 border-b md:border-l bundle-challenge-content">
                  <span className="mb-2 block text-term-primary font-poppings-bold bundle-span text-p3 md:text-sh3">
                    02
                  </span>
                  <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    This high-powered site is the best solution with custom
                    features that blew our client away.
                  </p>
                </div>

                <div className="md:px-4 py-3 lg:py-5 w-full md:w-1/2 border-b bundle-challenge-content">
                  <span className="mb-2 block text-term-primary font-poppings-bold bundle-span text-p3 md:text-sh3">
                    03
                  </span>
                  <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    Users love the site and found it easy to collaborate. They
                    are now seeing amazing economic recovery thanks to the site
                  </p>
                </div>
                <div className="md:px-4 py-3 lg:py-5 w-full md:w-1/2 border-b md:border-l bundle-challenge-content">
                  <span className="mb-2 block text-term-primary font-poppings-bold bundle-span text-p3 md:text-sh3">
                    04
                  </span>
                  <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    Shoppers are now mixing and matching bundles in real time
                    and serving as their own travel agents in a fast, secure
                    location.
                  </p>
                </div>

                <div className="mt-6 w-full">
                  <h3 className="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                    Our clients are our partners. Let's connect and create the
                    perfect virtual space for you!
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* slider section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="case-study-slider-bg">
            <div className="container container-cor mx-auto">
              <div
                id="myid"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                <Flickity
                  className={"case-studies-carousel cstm-du-casestudy-slider"}
                  elementType={"div"}
                  options={options}
                  ref={flRef}
                >
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={bundles1}
                    alt="Bundle Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={bundles2}
                    alt="Bundle Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={bundles3}
                    alt="Bundle Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={bundles4}
                    alt="Bundle Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={bundles5}
                    alt="Bundle Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={bundles6}
                    alt="Bundle Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={bundles7}
                    alt="Bundle Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={bundles8}
                    alt="Bundle Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={bundles9}
                    alt="Bundle Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={bundles10}
                    alt="Bundle Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={bundles11}
                    alt="Bundle Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={bundles12}
                    alt="Bundle Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={bundles13}
                    alt="Bundle Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={bundles14}
                    alt="Bundle Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={bundles15}
                    alt="Bundle Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image rounded-2xl "
                    src={bundles16}
                    alt="Bundle Decodeup Technologies"
                  />
                  {/* {info.screenshots_for_carousel.map(
                        ({ image: img }, ss) => (
                          <img
                            key={ss}
                            src={getSrc(img)}
                            className="cs-carousel-image"
                            alt="decodeup case study"
                          />
                        )
                      )} */}
                </Flickity>
              </div>
            </div>
          </div>
        </section>

        {/* client testimonials carousel section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-cor mx-auto">
            <div className="pr-2 pl-5-3 lg:pl-60 lg:pr-60 xl:px-90 xl:pl-1-41 xl:pr-1-41 2xl:pl-1-86 2xl:pr-1-87">
              <TestimonialsText />
            </div>
          </div>
        </section>

        {/* additional case studies section */}

        <section className="pb-50 md:pt-50 lg:pt-62 xl:pt-0 lg:pb-1-20">
          <div className="container mx-auto">
            <div className="pb-35 lg:pb-36 text-center">
              <h3 className="d-head-title text-rp1  lg:text-h3 font-poppings-bold text-term-primary">
                Additional Case Studies
              </h3>
            </div>
            <div className="h-full flex flex-wrap">
              {/* <div className="px-4-1 pt-36 w-full sm:w-1/2 text-center bg-cyan">
                <Link
                  to="/case-study/beauty-products-ecommerce-store"
                  className="h-full flex items-center justify-between flex-col text-center addi-case-links"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    Beauty Products eCommerce Website
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className=""
                      src={addcs1}
                      alt="USA Mahoni Decodeup Technologies"
                    />
                  </div>
                </Link>
              </div> */}
              <div className="px-4-1 pt-36 w-full sm:w-1/2 text-center bg-background-case">
                <Link
                  // to={post.fields.slug}
                  to="/case-study/school-bus-pass-idcards-management-system"
                  className="h-full flex items-center justify-between flex-col text-center addi-case-links"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    School Bus Pass & ID Cards Management System
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className=""
                      src={addcs2}
                      alt=" Greenwood Bus Decodeup Technologies"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <LiveChat />
      </Layout>
    </>
  )
}

export default Bundle

export const pageQuery = graphql`
query bundlepagequery {
  pageData: allMarkdownRemark(
    filter: { fileAbsolutePath: { regex: "/case-studies/travel-hospitality-deals-platform.md/" } }
  ) {
    edges {
      node {
        frontmatter {
          seo_metadata {
            seo_title
            seo_keyword
            seo_description
            image_format
            feature_image_height
            img_width
            facebook_url
            twitter_page_username
            twitter_image_alt_tag
            twitter_estimated_reading_lable
            twitter_estimated_reading_time
            page_publish_date
            page_last_modified_time
            seo_Image {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 1200)
              }
            }
            seo_Single_Image
          }
          Schema {
            sitelinks_schema
            breadcrumb_schema
            logo_schema
            organization_schema
            aggregate_rating_schema
            local_business_schema
            site_navigation_schema
            speakable_specification_schema
          }
          Hreflang {
            hreflang_1
            hreflang_2
            hreflang_3
            hreflang_4
          }
        }
      }
    }
  }
}
`